import {UserActionTypes} from "./user.types";
import {ActionInterface} from "../ActionInterface";
import {UserInterface} from "./user.interface";

const INITIAL_STATE: UserInterface = {
    username: "",
    firstName: "",
    lastName: "",
    token: ""
}

const userReducer: any = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case UserActionTypes.SET_USERNAME:
            return {
                ...state,
                username: action.payload
            };
        case UserActionTypes.SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.payload
            };
        case UserActionTypes.SET_LAST_NAME:
            return {
                ...state,
                lastName: action.payload
            };
        case UserActionTypes.SET_USER_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case UserActionTypes.RESET_USER_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default userReducer;
