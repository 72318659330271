import {ActionInterface} from "../ActionInterface";
import {ModalInterface} from "./modal.interface";
import {ModalActionTypes} from "./modal.types";

const INITIAL_STATE: ModalInterface = {
    show_loading_screen: false,
    error_modal_screen: {
        image: "",
        label: "",
        content: "",
        okButton: false,
        show: false
    }
}

const modalReducer: any = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case ModalActionTypes.SET_LOADING_SCREEN:
            return {
                ...state,
                show_loading_screen: action.payload
            } as ModalInterface;
        case ModalActionTypes.SET_ERROR_MODAL_SCREEN:
            return {
                ...state,
                error_modal_screen: action.payload
            } as ModalInterface;
        default:
            return state;
    }
}

export default modalReducer;
