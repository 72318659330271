import React, { Component } from 'react';
import {GMAIL_API_CLIENT_ID} from "../../constants/Config";
import Authenticate from "../../webrequests/requestwrapper/Authenticate";
import {Connector} from "../../../../redux/Connector";

declare global {
    interface Window {
        google: any;
    }
}

class GoogleAuthenticateWeb extends Component {

    constructor(props: any) {
        super(props);
        this.loadGoogleAuthentication = this.loadGoogleAuthentication.bind(this);
        this.handleCallbackResponse = this.handleCallbackResponse.bind(this);
    }

    loadGoogleAuthentication() {
        if(window.google === undefined) {
            console.error("Google Auth load error");
            return;
        }

        window.google.accounts.id.initialize({
            client_id: GMAIL_API_CLIENT_ID,
            callback: this.handleCallbackResponse
        });

        window.google.accounts.id.renderButton(
            window.document.getElementById("google-login-button"),
            { theme: "outline", size: "large", width: 340}
        );

        window.google.accounts.id.prompt();
    }

    handleCallbackResponse(response: any) {
        if(response === undefined || response.credential === undefined) {
            console.warn("Something wrong with login");
        }

        Authenticate(this.props, response.credential);
    }

    componentDidMount() {
        this.loadGoogleAuthentication();
    }

    render() {
        return (
            <div id="google-login-button" />
        );
    }
}

export default Connector(GoogleAuthenticateWeb);
