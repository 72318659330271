import { VentActionTypes } from "./vent.types";

export const setVentPositive = (text: string) => (
    {
        type: VentActionTypes.SET_VENT_POSITIVE,
        payload: text
    }
)

export const setVentNegative = (text: string) => (
    {
        type: VentActionTypes.SET_VENT_NEGATIVE,
        payload: text
    }
)
