// modal
import {ModalDataInterface} from "./modal/modal.interface";

export function ShowLoadingScreen(props:any, isShow: boolean) {
    props.showLoadingScreen(isShow);
}

export function SetErrorModalScreen(props:any, modelData: ModalDataInterface) {
    props.setErrorModalScreen(modelData);
}

// user
export function SetUserName(props:any, username: string) {
    props.setUserName(username);
}

export function SetFirstName(props:any, firstName: string) {
    props.setFirstName(firstName);
}

export function SetLastName(props:any, lastName: string) {
    props.setLastName(lastName);
}

export function SetToken(props:any, token: string) {
    props.setToken(token);
}

export function ResetUserData(props: any) {
    props.resetUserData();
}


// vent
export function SetVentPositive(props: any, text: string) {
    props.setVentPositive(text);
}

export function SetVentNegative(props: any, text: string) {
    props.setVentNegative(text);
}
