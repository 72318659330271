import {connect} from "react-redux";
import {
    setErrorModalScreen,
    showLoadingScreen
} from "./modal/modal.actions";
import {setToken, resetUserData, setUserName, setFirstName, setLastName} from "./user/user.actions";
import {ComponentType} from "react";
import {ModalDataInterface, ModalInterface} from "./modal/modal.interface";
import {UserInterface} from "./user/user.interface";
import {setVentNegative, setVentPositive} from "./vent/vent.actions";
import {VentInterface} from "./vent/vent.interface";

const mapStateToProps = ({ user, modal, vent}: {user: UserInterface, modal: ModalInterface, vent: VentInterface}) => (
    {
        user: user,
        modal: modal,
        vent: vent
    }
)

const mapDispatchToProps = (dispatch: any) => ({
    // modal
    showLoadingScreen: (isShow: boolean) => dispatch(showLoadingScreen(isShow)),
    setErrorModalScreen: (modelData: ModalDataInterface) => dispatch(setErrorModalScreen(modelData)),

    // user
    setUserName: (username: string) => dispatch(setUserName(username)),
    setFirstName: (firstName: string) => dispatch(setFirstName(firstName)),
    setLastName: (lastName: string) => dispatch(setLastName(lastName)),
    setToken: (token: string) => dispatch(setToken(token)),
    resetUserData: () => dispatch(resetUserData()),

    // vent
    setVentPositive: (text: string) => dispatch(setVentPositive(text)),
    setVentNegative: (text: string) => dispatch(setVentNegative(text))
})

export const Connector = (component: ComponentType) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}
