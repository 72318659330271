import {GetAppBackendUrl} from "../../utils/PixieMartUtil";

const API_PREFIX = "/api";

// AUTHENTICATE AND AUTHORIZATION
export const GET_USER_API = GetAppBackendUrl() + API_PREFIX + "/user";
export const GET_USER_ACCOUNT_API = GET_USER_API + "/account";
export const LOGOUT_API = GetAppBackendUrl() + API_PREFIX + "/logout/";
export const GET_TOKEN_API = GetAppBackendUrl() + "/auth/authenticate/token/google_oauth2";
export const POSITIVE_VENT_API = GetAppBackendUrl() + "/api/pixie/vent/submit/positive";
export const NEGATIVE_VENT_API = GetAppBackendUrl() + "/api/pixie/vent/submit/negative";
export const STATUS_VENT_API = GetAppBackendUrl() + "/api/pixie/vent/status";
