import {Button, FormControl} from "react-bootstrap";
import {SetVentNegative} from "../../../../redux/ConnectorWrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GetLoginPageUrl} from "../../utils/PixieMartUtil";
import {Link} from "react-router-dom";
import {useState} from "react";
import {SubmitForm} from "./SubmitForm";
import {NEGATIVE_VENT_API} from "../../constants/backend/APIConstant";

function NegativeBlock({props, ventStatus}: {props: any, ventStatus: boolean}) {
    const [isSubmitted, setIsSubmitted] = useState(ventStatus);

    return <div className={"bg-white p-3 my-3 rounded shadow-sm border"}>
        <form onSubmit={(event) => SubmitForm(props, NEGATIVE_VENT_API, event, setIsSubmitted)}>
            <h4>
                <label className={"small fw-bold w-100"}>
                    Write at least one thing you are thankful for today? {
                    isSubmitted && <div className={"fw-bold text-success float-end"}>
                        <FontAwesomeIcon icon={"check-circle"}/> Submitted
                    </div>
                }
                </label>
            </h4>
            {
                !isSubmitted && <div>
                    <FormControl className={"shadow-none small"} as={"textarea"} name={"negativeVent"}
                                 placeholder={"Write at least one thing you are thankful for today"}
                                 rows={10} value={props.vent.negativeVent}
                                 onChange={(event) => SetVentNegative(props, event.target.value)}/>
                    <div className={"text-center pt-3"}>
                        {props.user.token ? <Button type={"submit"} className={"rounded-5 w-25"}>Save</Button> :
                            <Button {...props} variant={"outline-primary"}
                                    className={"my-3"}
                                    as={Link}
                                    to={GetLoginPageUrl()}>
                                <FontAwesomeIcon icon={"sign-in-alt"} className={"me-1"}/> Save
                            </Button>
                        }
                    </div>
                </div>
            }
        </form>
    </div>
}

export default NegativeBlock;
