import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import modalReducer from "./modal/modal.reducer";
import ventReducer from "./vent/vent.reducer";

export default combineReducers(
    {
        user: userReducer,
        modal: modalReducer,
        vent: ventReducer
    }
)
