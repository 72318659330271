import {Dispatch, SetStateAction} from "react";
import {JsonFromFormEvent} from "../../utils/FormManager";
import {BackendPOSTRequest} from "../../webrequests/BackendRequest";

export function SubmitForm(props: any, url: string, event: any, setIsSubmitted: Dispatch<SetStateAction<boolean>>) {
    event.preventDefault();
    const data = JsonFromFormEvent(event);
    setIsSubmitted(true);
    BackendPOSTRequest(props, url, data, (response) => {
        console.log("Response: ", response);
    }, (error) => {
        console.error(error);
        setIsSubmitted(false);
    });
    return false;
}
