import {LOGIN_PAGE_URL} from "../constants/application/ApplicationUrl";
import {Base64} from 'js-base64';

export function GetAppBackendUrl() {
    return process.env.REACT_APP_BACKEND_URL || "";
}

export function GetLoginPageUrl() {
    return LOGIN_PAGE_URL + "?redirect=" + Base64.encodeURI(window.location.pathname);
}
