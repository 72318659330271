import PositiveBlock from "./PositiveBlockComponent";
import NegativeBlock from "./NegativeBlockComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {STATUS_VENT_API} from "../../constants/backend/APIConstant";
import {Connector} from "../../../../redux/Connector";
import {Spinner} from "react-bootstrap";

function VentItOutHome(props: any) {
    const currentDate = new Date();
    const [ventStatus, setVentStatus] = useState({positiveSubmitted: false, negativeSubmitted: false});
    const [loading, setIsLoading] = useState(true);

    // Manually format the date and time
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = currentDate.toLocaleString("en-US", {month: "short"});
    const year = currentDate.getFullYear().toString().slice(-2);
    const time = currentDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    }).toLowerCase(); // This ensures "am/pm" is lowercase

    const formattedDate = `${day} ${month} ${year}, ${time}`;

    useEffect(() => {
        BackendGETRequest(props, STATUS_VENT_API, (response) => {
            setVentStatus(response.body);
            setIsLoading(false);
        }, () => {
            setIsLoading(false);
        });
    }, [props]);

    return <section className={"d-flex flex-fill bg-light-subtle"}>
        <div className={"container pb-5"}>
            <div className={"text-center"}>
                <h1 className={"fw-bold"}>Vent it Out!</h1>
                <p className={"fw-bold text-secondary"}>{formattedDate}</p>
            </div>
            {
                loading ? <div className={"p-3 text-center bg-white rounded my-2"}>
                    <Spinner size={"sm"} /> Loading vent status ...
                </div> : <div>
                    <PositiveBlock ventStatus={ventStatus.positiveSubmitted} props={props}/>
                    <NegativeBlock ventStatus={ventStatus.negativeSubmitted} props={props}/>
                </div>
            }
            <div>
                <div className={"small rounded p-2 bg-white shadow-sm"}>
                    <p className={"m-0"}><FontAwesomeIcon icon={"info-circle"}/> Your data is completely secure, and you
                        have the option to delete it at any time.</p>
                    <p className={"m-0"}>Additionally, we automatically delete all stored data on a weekly basis, even
                        if you do not request its deletion.</p>
                    <p className={"m-0"}>For deleting your data immediately email us at <a
                        className={"no-text-decor fw-bold"}
                        href={"mailto:pixiemartindia@gmail.com"}>pixiemartindia@gmail.com</a></p>
                </div>
            </div>
        </div>
    </section>
}

export default Connector(VentItOutHome);
