import {ActionInterface} from "../ActionInterface";
import {VentInterface} from "./vent.interface";
import {VentActionTypes} from "./vent.types";

const INITIAL_STATE: VentInterface = {
    positiveVent: "",
    negativeVent: "",
}

const ventReducer: any = (state = INITIAL_STATE, action: ActionInterface) => {
    switch (action.type) {
        case VentActionTypes.SET_VENT_POSITIVE:
            return {
                ...state,
                positiveVent: action.payload
            } as VentInterface;
        case VentActionTypes.SET_VENT_NEGATIVE:
            return {
                ...state,
                negativeVent: action.payload
            } as VentInterface;
        default:
            return state;
    }
}

export default ventReducer;
