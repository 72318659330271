import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {HOME_URL, LOGIN_PAGE_URL} from "../../constants/application/ApplicationUrl";
import VentItOutHome from "../home/VentItOutHome";
import {LoginPage} from "../login/LoginPage";

function App() {
    return (
        <div className="App d-flex flex-column">
            <div className={"flex-fill d-flex flex-column"}>
                <Routes>
                    <Route path={HOME_URL + "/*"} element={<VentItOutHome />}/>
                    <Route path={LOGIN_PAGE_URL} element={<LoginPage />}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
