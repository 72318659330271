import {GET_TOKEN_API, GET_USER_API} from "../../constants/backend/APIConstant";
import {BackendGETRequest, BackendPOSTRequest} from "../BackendRequest";
import {SUCCESS_STATUS} from "../../constants/Constant";
import {LOGIN_PAGE_URL} from "../../constants/application/ApplicationUrl";
import axios from "axios";
import {optionProvider} from "../RequestConfig";
import {SetFirstName, SetLastName, SetToken, SetUserName} from "../../../../redux/ConnectorWrapper";
import {Base64} from "js-base64";
import {UserInterface} from "../../../../redux/user/user.interface";

export function GetUser(props: any) {
    BackendGETRequest(props, GET_USER_API, (response) => {
        if(response.status === SUCCESS_STATUS) {
            PostSuccessGetUser(props, response);
        }
    }, (error) => {
        console.error(error);
    });
}

function GetUserWithOptions(props: any, tokenId: string) {
    let options = optionProvider(props);
    options.headers.Authorization = tokenId;

    axios.get(GET_USER_API, options)
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                PostSuccessGetUser(props, response.data);
                return;
            }
            console.error(response);
        })
        .catch(error => {
            console.error(error);
        });
}

function PostSuccessGetUser(props: any, response: {body: UserInterface }) {
    SetFirstName(props, response.body["firstName"]);
    SetLastName(props, response.body["lastName"]);
    SetUserName(props, response.body["username"]);

    if(window.location.pathname.includes(LOGIN_PAGE_URL)) {
        let redirect = new URLSearchParams(window.location.search).get("redirect");
        let urlToRedirect = window.location.protocol + "//" + window.location.host;
        if(redirect) {
            urlToRedirect += Base64.decode(redirect);
        }

        window.location.href = urlToRedirect;
    }
}

export default function Authenticate(props: any, tokenId: string) {
    BackendPOSTRequest(props, GET_TOKEN_API, {"token": tokenId}, (response) => {
        SetToken(props, response.message);
        GetUserWithOptions(props, response.message);
    }, (error) => {
        console.log("LOGIN Failed", error);
    });
}

export function OnClickSignOut(props: any) {
    alert("WIP");
}
